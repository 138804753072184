
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import _ from "lodash"
import { useLocation } from "@reach/router"
function SeoStructuredData({ type, publisher, total, entity ,title,description}) { 
  const [render, setRender] = useState(false);  
  // var ldJson = {
  //   "@context": "http://schema.org",
  //   "@type": type,
  // };

  // if (!_.isEmpty(publisher)) {
  //   ldJson['publisher'] = {
  //     "@type": "Corporation",
  //     "name": publisher.name,
  //     "logo": {
  //       "@type": "ImageObject",
  //       "url": publisher.logo,
  //       "width": 243,
  //       "height": 34
  //     }
  //   }
  // }
  // if (!_.isEmpty(entity)) {
  //   var itemListElement = [];
  //   if (!_.isEmpty(entity.list)) {
  //     _.forEach(entity.list, (val, key) => {
  //       itemListElement.push(
  //         {
  //           "@type": "ListItem",
  //           "position": key+1,
  //           "url": val.url,
  //           "name": val.name
  //           // "item": {
  //           //   "@type": "ListItem",
  //           //   "@id": val.id,
  //           //   "url": val.url,
  //           //   "name": val.name
  //           // }
  //         }
  //       ) 
  //     }) 
  //   }

  //   ldJson['mainEntity'] = {
  //     "@type": "ItemList",
  //     "numberOfItems": total,
  //     "name": entity.name,
  //     "description": entity.desc,
  //     "itemListElement": itemListElement
  //   }
  // }
  useEffect(() => {
    if (!render) {
      setRender(true)
    }
  })
  var { pathname } = useLocation()
  var siteUrl = process.env.GATSBY_SITE_URL;
  var schemaUrl = siteUrl + pathname ;
  console.log("schemaurl",schemaUrl)
  var ldJson ={
    "@context": "https://schema.org/", 
    "@type": "Product", 
    "name":   `${title} | Arabian Estates`,
    "image": "https://ggfx-arabianestates2.s3.eu-west-2.amazonaws.com/i.prod/property/67a5bd8ec352cfc745f4d24b/images/propertybase-clients/00D8d000005AHV4EAO/a0k8d000003ajWJ/5y0ypr2h5/PH1%2520-Trident%2520WM-64%2520%25281%2529_GGFX_V_1.jpg",
    "description":`${description}`,
    "brand": {
      "@type": "Brand",
      "name": "Arabian Estates"
    },
    "offers": {
      "@type": "AggregateOffer",
      "url": `${schemaUrl}`,
      "priceCurrency": "AED",
      "lowPrice": "300000",
      "highPrice": "50000000"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "bestRating": "5",
      "worstRating": "1",
      "ratingCount": "120"
    }
  }
  
  
  return (
    <Helmet>
      {title !== undefined && description !== undefined && render &&
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>}
    </Helmet>
  )
}


export default SeoStructuredData
